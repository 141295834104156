@font-face {
  font-family: 'Avenir Next';
  font-weight: 200;
  src: local('Avenir Next'), url(./fonts/AvenirNext-UltraLight-11.woff) format('woff');
}

@font-face {
  font-family: 'Avenir Next';
  font-weight: 400;
  src: local('Avenir Next'), url(./fonts/AvenirNext-Regular-08.woff) format('woff');
}

#root {
  background-color: #1C1C1C;
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.00) 0%, #000 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%);
  background-size: cover;
  font-size: 12px;

}

body {
  overflow-y: scroll; 
  background-color: #1C1C1C;
  font-family: 'Avenir Next', sans-serif;
  text-rendering: geometricPrecision;
}

 * {
  transition: all .4s;
}

.logo{
  width: 91px;
}

.bg-container {
  display: flex;
  width: 100%;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100vH;
  z-index: 0;
}

.bg-image {
  width: 100%;
  opacity: .5;
}

.shield {
  width: 99px;
  margin-bottom:  -15px;
}

.neo-shield-app {
  display: flex;
  min-height: 100vH;
  width: 100%;
  align-items: start;
  justify-content: center;
  color: #fff;
  z-index: 1;
  position: relative;
}

.neo-shield-header {
  background-color: transparent;
  color: white;
  padding: 2rem;
  position: absolute;
  z-index: 10;
}

.neo-form {
  width: 90%;
  max-width: 700px;
}

.neo-shield-form-wrapper {
  margin-top: 200px;
  border: 15px solid rgba(100,100,100,.5);
  border-radius: 20px;
  position: relative;
}

.neo-shield-form {
  background: #000;
}

.svg_icons {
  transform: scale(.8);
}

.tabs{
  margin:0 auto;
  width:100%;
  text-align:center;
  display: flex;
  justify-content: center;
}

.tab-titles {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: -100px;
}
.tab-button{
  font-size: .8rem;
  padding: 4px 20px;
  margin: 20px;
  transition: all ease-in-out .4s;
  cursor: pointer;
}
.tab-button.active{
  background: #717EBA;
  border-radius: 40px;
}
.tab-content {
  width: 100%;
  padding: 30px;
  background: #000;
  min-height: 158px;
  justify-content: center;
  align-items: center;
  position: relative;
}
.tab-inner {
  position: relative;
  width: calc(100% - 60px);
  overflow: hidden;
}
.tab-text{
  width: 100%;
  text-align: left;
}

.tab-url,
.tab-url input,
.tab-url input::placeholder{
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: 200 !important;
  font-family: 'Avenir Next';
}

.tab-text textarea{
  width: 100%;
  font-size: 2rem;
  font-weight: 200 !important;
  font-family: 'Avenir Next';
}

.tab-upload{
  font-size: 2rem;
  font-weight: 200 !important;
  font-family: 'Avenir Next';
}

.button{
  font-size:15px;
  background:transparent;
  border:none;
  outline:none;
  padding:17px;
  cursor:pointer;
  transition: all ease-in-out .4s;
  border-radius: 30px;
}

.button-outline{
  border:1px solid #fff;
}

.button-primary {
  background-color: #fff;
  color: #000;
  padding: 17px 80px;
  opacity: 1;
}
.button-primary:disabled{
  opacity: .5;
  cursor: not-allowed;
  color: #575757;
}

.preferences {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  height: 0;
  transition: all .4s;
}

.tab-preferences.true .tab-titles {
  opacity: 0;
  z-index: 0;
  transition: opacity ease .2s;
}

.tab-preferences{
  overflow: hidden;
  transition: max-height ease .4s;
}

.edit-preferences{
  overflow: hidden;
  transition: max-height ease .4s;
}

.text-center {
  text-align: center;
}

.col {
  text-overflow: ellipsis;
  overflow: hidden;
}

.col-1{
  text-align: left;
  width: 33.33333%;
  line-height: 40px;
}

.col-2{
  text-align: right;
  width: 66.6666%;
}
.MuiFormControl-root {
  width: 100%;
}

.col-2 .MuiFormGroup-root,
.col-2.matrix-titles {
  display: flex;
  justify-content: space-between;
}

.matrix-titles {
  margin: 30px 0 15px 0;
}

.edit-preferences.true {
  max-height: 500px;
}

.settings-container {
  height: auto;
  min-height: 80px;
  width: 100%;
  text-align: center;
  transition: all .2s;
}

.matrix.row {
  padding: 0 40px;
  display: flex;
  width: auto;
  justify-content: space-between;

}

.row.additional-fields{
  padding: 20px;
  display: flex;
  justify-content: space-around;
  max-width: 800px;
}

.row.additional-fields input{
  width: 128.366px;
flex-shrink: 0; 
  border-radius: 4px;
  border: 0.5px solid #575757;
  background: #000; 
  color: #636363;
  margin-left: 5px;
  padding: 10px;
}

.row.additional-fields label{
  text-align: center;
}

.row.submit{
  padding: 20px;
  display: flex;
  justify-content: center;
  max-width: 800px;
}



.bg-gradient {
  background: linear-gradient(153.07deg, rgba(75, 86, 137, 0) 16.84%, rgba(113, 126, 186, 0.5) 58.32%), radial-gradient(41.43% 3115.43% at 23.78% -54.29%, rgba(255, 123, 250, 0.2) 0%, rgba(255, 123, 250, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(90.65deg, #B365B0 0%, #7445A8 50%, #717EBA 100%);
  border-radius: 3px;
}

input, textarea {
  background: transparent;
  color: #fff;
  border: 0;
  outline: 0;
}

label,.MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 12px !important;
  font-family: 'Avenir Next' !important;
  white-space: nowrap;
}

#app-response {
  display: none;
  position: fixed;
  opacity: 0;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  width: 100%;
  height: 100vH;
  color: #fff;
  z-index: 1;
  top: 0;
  left: 0;
  padding: 0;
  text-align: center;
  font-weight: 200;
}